import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import Button from "react-bootstrap/Button";
import PricingCardLarge from "../../components/pricing-card-large";

const BusinessMotPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "17.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				kolos: file(relativePath: { eq: "testimonials/Kolos-Tapler.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				jessC: file(relativePath: { eq: "testimonials/Jessica Cox.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Business MOT/Health Check";
	const description =
		"Business MOT/Health Check, when firing on all cylinders it will get you from where you are to where you want to be in your career and life journey.";
	const heroImg = data.heroImg.childImageSharp.fluid;
	const kolos = data.kolos.childImageSharp.gatsbyImageData;
	const jessC = data.jessC.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Business MOT/Health Check",
				item: {
					url: `${siteUrl}/business-mot-health-check`,
					id: `${siteUrl}/business-mot-health-check`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/business-mot-health-check`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Business MOT/Health Check with Jason Cornes",
						},
					],
				}}
			/>
			<Hero
				isDarken={true}
				backgroundImage={heroImg}
				title="Business MOT/Health Check"
				description1="Is your small business firing on all cylinders?"
				description2="Book yours in for a Business MOT/Health Check today!"
			/>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<p>
								You put your car through an MOT each year, but what about your
								small business?{" "}
							</p>
							<p>
								Your car’s MOT highlights what may be broken, needing adjustment
								or replacement to ensure another year of trouble free motoring.
								And a car that’s passed it’s MOT will most likely get you from A
								to B safely and efficiently for the next 12 months.{" "}
							</p>
							<p>
								The same goes for your small business, when it’s firing on all
								cylinders it will get you from where you are to where you want
								to be in your career and life journey, including perhaps that
								new car you have your eye on!
							</p>
							<p>
								Every year like clockwork we book our car in for it’s MOT, so -
								is it about time you asked an independent expert to look at your
								small business?{" "}
							</p>
							<p>
								As self-employed business owners we often cannot see things as
								clearly as we would like, partly because we are so busy working
								IN the business rather than ON the business.
							</p>
							<p>
								So let me ask you - when was the last time you slowed down and
								had a good hard look at your business, the thing that’s putting
								money in your pocket and keeping a roof over your family’s head,
								to ensure it’s running as efficiently and profitably as it can
								be?
							</p>
							<ul>
								<li>
									What small changes and in what order could you make to get the
									results you deserve?
								</li>
								<li>Could you be missing out on new customers or markets?</li>
								<li>
									Does your sales funnel leak? What else could be working better
									and how?
								</li>
								<li>
									Are you suffering from rusty or old operating procedures?
								</li>
								<li>
									What is the return on investment on your business expenditure?
								</li>
								<li>
									Perhaps you’re feeling ambitious but stuck on what to do next?
								</li>
								<li>
									Do you have a clear vision? Are you following a strategy to
									get you there?{" "}
								</li>
							</ul>
							<p>
								As with our car analogy, you may well have a sense your small
								business could be running better but unless you’re able to slow
								down and take a fresh look at things with a business mentor or
								coach, it’s difficult to diagnose - let alone prescribe the
								right solution to get your small business ’roadworthy’ and
								performing at its best.{" "}
							</p>
							<p>
								The only way to know for sure is to get an expert to look under
								the bonnet and have a good root around, which is precisely what
								my business MOT will do for you.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Could your business do with a tune-up? </h2>
							<p>
								My business MOT will be bespoke to you and your small business
								however in general it will looks at the various spokes that make
								up your business wheel, covering:
							</p>
							<ul>
								<li>
									You as the business owner and how you got to where you are
								</li>
								<li>Your vision and strategy - where you are headed</li>
								<li>Sales</li>
								<li>Marketing</li>
								<li>Finance</li>
								<li>Operations</li>
							</ul>
							<p>
								Which, put together as a whole, allows us to gather a
								big-picture look and assessment of where you and your small
								business are now and you’ll receive suggestions, ideas and
								recommendations of what needs to be worked on and how.{" "}
							</p>
							<p>
								Your small business MOT works to uncover areas that could be
								losing you money or blocking your progress while developing
								solutions which, sometimes with just a little fine tuning, can
								get you to where you want to be quicker and with less effort. To
								help you to focus on what’s important.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								Who am I, and why should you listen to me?{" "}
							</h2>
							<p>
								My name’s Jason Cornes; I’m a small business and executive coach
								based on the South Coast, roughly 60-miles from Central London.
								I have 35+ years of first-hand business experience behind me. In
								fact, I’ve never had a job, having been self-employed for my
								entire adult life. I’m honest with my clients about my successes
								and of course numerous mistakes I’ve made along the way.{" "}
							</p>
							<p>
								In this time, I’ve done it all small business wise, and have a
								huge wealth of self-employed experience and business knowledge.{" "}
							</p>
							<p>
								Nowadays, I share my own exciting journey in business, my
								qualifications (DMS & MBA) and five years of counselling and
								psychotherapy training to help self-employed people like
								yourself, with any type of small business, decide what they
								want, hit their goals and get to where they want to be in life.{" "}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container>
					<HorizontalTestimonial
						review="I was inspired to book a business MOT with Jason as I heard other business owners say how useful it was for them. It might seem contrary to what is expected or even ironic that the owner of a firm of accountants, that look after and advise small business clients, albeit with a financial focus, would himself want a business MOT. The reality is that all of us can get ‘lonely in business’ and benefit from a fresh perspective and a safe space to explore what we really want for ourselves and our team and how we are going to achieve that. My business MOT with Jason took place over one month in which we had three meetings. I had the option to contact between meetings if I had a question, felt stuck or wanted to update Jason. Each meeting explored what is important to me and we agreed action points for which I was held accountable.  I approached our first meeting with an open mind and quickly realised that Jason is very experienced in business and a great listener. I was able to explore with Jason what I really want for myself and my team and celebrate our strengths. Thinking strategically, we were also able to identify many tweaks, changes and improvements to enhance an already successful accountancy practice. I’m very much looking forward to working with Jason going forward and have already recommended several of my own clients for business coaching"
						name="Kolos Tapler -  Accountant. No 1 Accountants Ltd"
						linkedIn="https://www.linkedin.com/in/kolostapler"
						profilePic={kolos}
					/>

					<div className="iframe-container-new mb-4">
						<iframe
							loading="lazy"
							className="responsive-iframe"
							src="https://www.youtube.com/embed/obUlkJ1DiRI"
							title="Testimonial | Jason Cornes"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
				</Container>
			</section>
			<section className="py-3">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Your customised business MOT</h2>
							<p>
								Let’s examine what’s working well within your business while
								taking an honest look at what may not be working so well. Don’t
								worry – you can be open and direct with me. Remember: I’ve been
								there too and I will not judge you. I’m here to help.
							</p>
							<p>
								After I have listened carefully to what you’d like to achieve
								from your small business MOT, we’ll identify what needs some
								work and how best to move forward. Actually, this is really
								exciting; everything is achievable if we break it down into
								small stages.
							</p>
							<p>
								Your business MOT will be tailored to your own individual
								circumstances and type of business.
							</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<h2 className="pb-3">
								Time to roll up your sleeves and get to work
							</h2>
							<p>
								At the end of the session, you’ll have a focused action plan to
								work on, chock-full of exciting new ideas, tasks and strategies.
								The next stage of your process is to roll up your sleeves and
								put all of our suggestions into practice.{" "}
							</p>
							<p>
								Since I’ve taken such a deep-dive into the way they operate,
								many of my business MOT clients find that it’s beneficial to
								have me onside to support them going forward.{" "}
							</p>
							<p>
								My ‘hidden business partner’ services are available for those
								MOT clients who would like more business coaching help, support
								and accountability to put the recommended changes into practice.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			{/* <section className="py-7" style={{ backgroundColor: "#FBFBFB" }}> */}
			<section className="py-7">
				<Container>
					<Row>
						<Col>
							<h2 className="text-center text-primary pb-7">
								Business MOT/Health Check Package
							</h2>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<PricingCardLarge
							title="Business MOT/Health Check"
							subText="Let’s explore opportunities for improvement and growth in your small business or practice."
							price={
								<span>
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										UK £995
									</span>{" "}
									<span className="text-primary font-weight-bold">+ VAT</span>
									<br />
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										USA $1500
									</span>{" "}
								</span>
							}
							features={[
								"3 sessions (4 hours) + complimentary follow up session",

								"A bespoke deep dive into your small business or practice",

								"Highlight improvements. Transform challenges into opportunities",
								"Create a list of action points and a timeline",

								"‘Homework’ and support between sessions as required",
								"A standalone option or continue to 6 or 12 Months Business & Executive Coaching",
							]}
						/>
					</Row>
				</Container>
			</section>

			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2>Ready to book your business in for it’s MOT?</h2>
							<p>
								As you can imagine, a business MOT isn’t as simple as dropping
								off the car keys and grabbing a coffee. It’s not a ‘while you
								wait’ service; it requires a time commitment from you of 3-4
								hours over 3 sessions and an open, honest and frank discussion.
								All our work together is of course completely confidential.{" "}
							</p>
							<p>
								So, if you feel ready to get your business firing on all
								cylinders - book a call with me today to discuss how we can work
								together.
							</p>
						</Col>
					</Row>
					<Row>
						<Col className="text-center pt-3">
							<Button
								variant="outline-light"
								size="lg"
								className="cta-btn cta-btn-gtm mr-lg-4 mb-4 mb-md-0"
								as={Link}
								to="/contact-us"
							>
								Book a discovery meeting
							</Button>

							<Button
								variant="outline-light"
								size="lg"
								className="cta-btn cta-btn-gtm"
								as={Link}
								to="/packages"
							>
								Packages
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};
export default BusinessMotPage;
